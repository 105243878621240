export const rangeOptionsColors = [
    {
        color: 'rgba(255, 0, 0, 1)',
        backgroundColor: 'rgba(255, 0, 0, 0.2)'
    },
    {
        color: 'rgba(212, 43, 0, 1)',
        backgroundColor: 'rgba(212, 43, 0, 0.2)'
    },
    {
        color: 'rgba(170, 85, 0, 1)',
        backgroundColor: 'rgba(170, 85, 0, 0.2)'
    },
    {
        color: 'rgba(128, 128, 0, 1)',
        backgroundColor: 'rgba(128, 128, 0, 0.2)'
    },
    {
        color: 'rgba(85, 170, 0, 1)',
        backgroundColor: 'rgba(85, 170, 0, 0.2)'
    },
    {
        color: 'rgba(43, 212, 0, 1)',
        backgroundColor: 'rgba(43, 212, 0, 0.2)'
    },
    {
        color: 'rgba(0, 255, 0, 1)',
        backgroundColor: 'rgba(0, 255, 0, 0.2)'
    },
    {
        color: 'rgba(60, 168, 97, 1)',
        backgroundColor: 'rgba(60, 168, 97, 0.2)'
    },
    // TODO: need to expand the list of color
    {
        color: 'rgba(91, 203, 201, 1)',
        backgroundColor: 'rgba(91, 203, 201, 0.2)'
    },
    {
        color: 'rgba(94, 211, 242, 1)',
        backgroundColor: 'rgba(94, 211, 242, 0.2)'
    },
    {
        color: 'rgba(46, 121, 218, 1)',
        backgroundColor: 'rgba(46, 121, 218, 0.2)'
    },
    {
        color: 'rgba(236, 78, 124, 1)',
        backgroundColor: 'rgba(236, 78, 124, 0.2)'
    },
    {
        color: 'rgba(233, 51, 63, 1)',
        backgroundColor: 'rgba(233, 51, 63, 0.2)'
    },
    {
        color: 'rgba(132, 76, 187, 1)',
        backgroundColor: 'rgba(132, 76, 187, 0.2)'
    },
    {
        color: 'rgba(224, 112, 77, 1)',
        backgroundColor: 'rgba(224, 112, 77, 0.2)'
    },
    {
        color: 'rgba(60, 168, 97, 1)',
        backgroundColor: 'rgba(60, 168, 97, 0.2)'
    }
]