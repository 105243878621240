export const rangeFiveOptionsColors = [
    {
        color: 'rgba(255, 0, 0, 1)',
        backgroundColor: 'rgba(255, 0, 0, 0.2)'
    },
    {
        color: 'rgba(191, 64, 0, 1)',
        backgroundColor: 'rgba(191, 64, 0, 0.2)'
    },
    {
        color: 'rgba(128, 128, 1)',
        backgroundColor: 'rgba(128, 128, 1, 0.2)'
    },
    {
        color: 'rgba(64, 191, 0, 1)',
        backgroundColor: 'rgba(64, 191, 0, 0.2)'
    },
    {
        color: 'rgba(0, 255, 0, 1)',
        backgroundColor: 'rgba(0, 255, 0, 0.2)'
    }
]